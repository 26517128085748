export const returns = [
  {
    title: "Income Slip's",
    desc: [
      "T3 Trust Income",
      "T4 Employment Income",
      "T4A Canadian Emergency Response Benefit (CERB)",
      "T4A bursary from College or other organization",
      "T4A Pension income or Commission Box 40 Box 28",
      "T4A (OAS) Old Age Pension",
      "T4A (P) Canada Pension",
      "T4E Employment Benefits",
      "T4RIF Income from a RIF",
      "T4RSP Income from RRSP",
      "T5 Bank Interest",
      "T5007 Social Assistance or WSIB or ODSP",
      "T5008 Capital Gain income",
      "T5018 Sub Contractors",
      "T2202 Tuition form from College or University",
      "ANY Other T slips you receive bring them in even if you are unsure",
    ],
  },
  {
    title: "Child Care Expenses",
    desc: [
      "receipts from day care for each child, usually claimed by the lower earner. There are max amounts. Child can only be claimed by ONE parent.",
    ],
  },
  {
    title: "Tution Receipt T2202",
    desc: [
      "Transferable to a max of $5000 Federal. TL11C(Commuter) from College or University. Students commuting to US for school. TL11A(Accommodations) for students that are living in the US while attending school during school year!",
    ],
  },
  {
    title: "Student Loan",
    desc: [
      "interest only claimable if interest is paid to National Student Loans.",
    ],
  },
  {
    title: "RRSP Contribution",
    desc: [
      "receipts-for the previous year (March 3rd, 2020 to December 31st, 2020 and January 1st, 2021 to March 1st, 2021. DEADLINE for contributions is March 1st, 2021",
    ],
  },
  {
    title: "Charitable Donation",
    desc: [
      "Please add all receipts together and give us the total. You can use our Medical Expense and Donations Form found on the FORMS tab      ",
    ],
  },
  {
    title: "Political Contribution",
    desc: ["receipts -bring in receipts"],
  },
  {
    title: "Property taxes or Rent",
    desc: [
      "This could help you to receive OTB (Ontario Trillium Benefit) and Ontario Senior Property tax grant eligibility. This credit is Geared to family’s income.",
    ],
  },
  {
    title: "Disability Tax Credit Letter",
    desc: [
      "If you have been approved for the Disability Tax Credit please provide us with your letter of approval from the Government.",
    ],
  },
  {
    title: "Medical Expenses",
    desc: [
      "receipts- medical expenses incurred in the year Jan 1 to Dec31 of the tax year for you and your dependents. Examples of useable medical receipts are prescriptions, glasses, dental, doctor fees, and insurance fees. Out of town medical costs must have receipts. Client must ADD all receipts before coming in. Please see our Medical Expenses and Donations Form available on our FORMS page. Totals only Please! Please total medical expenses for each family member separately. * Totals must equal at least 6% of client’s income in order to be used as a deduction.",
    ],
  },
  {
    title: "New Client",
    desc: [
      "please Bring in your last years completed tax return and Notice of Assessment. NOA this will have any unused amounts or carry forwards available to be used, and any other important information.",
    ],
  },
  {
    title: "RRSP Home Buyer's repayment",
    desc: [
      "information needed the withdrawal year, amount and repayment, this is also found on the NOA. If you have claimed the Home Buyers amount in the past you MUST bring in your Notice of Assessment from the last tax year.",
    ],
  },
  {
    title: "Self-Employed",
    desc: [
      "If you worked from home this year and would like to claim your employment expenses using the simplified method please provide us with the total number of days you worked from home. If you would like to claim your expenses using the detailed method please provide the T2200 from your employer along with you employment expenses.  Please see our Small Business Worksheet on our FORMS page.",
    ],
  },
  {
    title: "Cap-Gains",
    desc: [
      "If you sold your principal residence please provide us with the purchase documents and the sale documents.",

      "If you sold shares we will need the purchase amount as well as the sale amount for your shares.  If you have multiple transactions from many banks please fill out our Capital Gains Form located in our FORMS tab.",

      "If you would like us to do this for you there may be an additional fee as deemed appropriate by the tax preparer.",
    ],
  },
];
