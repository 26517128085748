import React from "react";
import Footer from "../components/Footer/Footer";
import { Disclosure } from "@headlessui/react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { faqsData } from "../constants/faqsData";
import Header2 from "../components/Header/Header2";
import { Link } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import FileTaxing from "../components/popup/FileTaxing";
import ScrollTop from "../components/scrollTop/ScrollTop";
import WhatsAppSvg from "../svg/WhatsAppSvg";

const deductions = [
  "Medical Expenses (Please complete our Medical Expense Worksheet)",
  "Charitable Donations (Please provide the total for your donations)",
  "Child Care Expenses: Please provide us with the total childcare expense per child.",
  "RRSP deduction: Provide us with copy of all your RRSP slips.",
  "Disability Tax Credit (Please bring your letter of acceptance from the Government or atleast confirm to us that you have this letter from the CRA)",
  "Tuition Transfer (If you would like to transfer your tuition credit to a parent or guardian please make mention of this)",
  "For claiming Ontario Trillium Benefit, please provide us with the Rent/Property tax you paid during the last year.",
  "For claiming simplified employment expenses which are based on the days you worked at home during Covid-19, please provide us with the number of days worked at home in 2020 during Covid period.",
  "For claiming detailed employment expenses such as claiming portion of your house rent, utilities, property taxes for working from home; fuel and vehicle expense for meeting clients, Telephone expenses for contacting clients, etc., you may have to provide an authorization form from your employer such as Form T2200.",
];

function Faq() {
  return (
    <>
      <ScrollTop />
      <div className="absolute top-0 left-0 right-0 z-10">
        <Header2 />
      </div>
      {/* <div className="fixed bottom-14 animate-bounce duration-700 ease-linear left-5 z-10">
        <WhatsAppSvg />
      </div> */}
      <FileTaxing />
      <div className="bg-about bg-bottom">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 max-w-7xl mx-auto pt-56 pb-32">
          <div className="col-span-1 px-5 lg:px-0">
            <p className="text-4xl lg:tracking-wider lg:leading-[55px] font-semibold text-white">
              Frequently Asked Questions
            </p>
            <p className="text-white mt-5 mb-16">
            Maurice Morneau Tax was founded by reputed accounting and taxation experts over
              three decades ago. Since the time of our advent, we have strived
              towards excellence in providing nothing but the best accounting
              services.
            </p>
            <p>
              <Link
                to={"/"}
                className="bg-gradient-to-l rounded from-[#2c4699] to-[#3c53be] uppercase text-white px-5 py-2.5 font-bold"
              >
                about us
              </Link>
            </p>
          </div>
          <div className="col-span-1"></div>
        </div>
        <div className="flex items-center justify-center pb-3 animate-bounce duration-300">
          <IoIosArrowDown size={28} className="text-white" />
        </div>
      </div>
      <div className="container mx-auto px-5 lg:px-0">
        <FaqDataForm />
        <div className="my-14">
          <p className="text-3xl font-medium mb-6">
            Deductions/Credits That You May Qualify For
          </p>
          <ul className="list-disc space-y-2 pl-6">
            {deductions.slice(0, 4).map((item) => (
              <li>{item}</li>
            ))}
          </ul>
          <p className="my-4 font-medium text-gray-600">
            <span className="text-red-600">**</span>
            Please note that you may be able to claim the RRSP’s only for the
            period of March to December of 2020 and January to February of 2021
            on your 2020 Personal Tax returns. That means, exclusively January
            and February 2020 must not be reported on your 2020 personal tax
            return as they were already have been reported on your 2019 personal
            tax return.
          </p>
          <ul className="list-disc space-y-2 pl-6">
            {deductions.slice(4, 8).map((item) => (
              <li>{item}</li>
            ))}
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
}

const FaqDataForm = () => {
  return (
    <div className="py-6 lg:px-0 px-5 ">
      {faqsData?.map((item) => (
        <Disclosure key={item._id}>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex text-gray-500 font-semibold w-full mt-4 justify-between border rounded p-6 text-left text-sm focus:outline-none">
                <span className={`${open && "text-black"}`}>
                  {item.question}
                </span>
                {!open ? <AiOutlinePlus /> : <AiOutlineMinus />}
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm">
                <ul className="list-disc space-y-2 ml-10">
                  {item.answers?.map((q, index) => (
                    <li key={index}>{q}</li>
                  ))}
                </ul>
                {item.note?.map((a) => (
                  <p key={a} className="mt-5">
                    <i>{a}</i>
                  </p>
                ))}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}
    </div>
  );
};

export default Faq;
