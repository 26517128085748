import { useContext } from "react";
import { Navigate } from "react-router-dom";
import {AuthContext} from '../context/AuthContext'


const ProtectedRoute = ({ children }) => {
    const { currentUser } = useContext(AuthContext);
    console.log('currrent',currentUser)
    console.log('children',children)

  // return currentUser ? children : <Navigate to="/" />;
};


export default ProtectedRoute