import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import Home from "./screens/Home";
import About from "./screens/About";
import Contact from "./screens/Contact";
import Faq from "./screens/Faq";
import FormPage from "./screens/Form";
import Services from "./screens/Services";
import Personal from "./screens/services/Personal";
import Corporate from "./screens/services/Corporate";
import Nonresident from "./screens/services/Nonresident";
import CrossBorder from "./screens/services/CrossBorder";
import UsTaxes from "./screens/services/UsTaxes";
import Trust from "./screens/services/Trust";
import Bookeeping from "./screens/services/Bookeeping";
import Login from "./screens/auth/Login";
import Leads from "./screens/admin/Leads";
import Usreturns from "./screens/returns/Usreturns";
import Careturns from "./screens/returns/Careturns";
import ProtectedRoute from "./protected/ProtectedRoute";
import Thankyou from "./components/thanks/Thankyou";
import NotFound from "./components/notfound/NotFound";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "about-us",
    element: <About />,
  },
  {
    path: "services",
    element: <Services />,
  },
  {
    path: "forms",
    element: <FormPage />,
  },
  {
    path: "frequently-asked-questions",
    element: <Faq />,
  },
  {
    path: "contact",
    element: <Contact />,
  },

  //services
  {
    path: "services/personal-income-tax-returns",
    element: <Personal />,
  },
  {
    path: "services/corporate-income-tax-returns",
    element: <Corporate />,
  },
  {
    path: "services/non-resident-taxes",
    element: <Nonresident />,
  },
  {
    path: "services/cross-border-taxes",
    element: <CrossBorder />,
  },
  {
    path: "services/us-federal-and-state-taxes",
    element: <UsTaxes />,
  },
  {
    path: "services/trust-taxes-for-deceased-individuals",
    element: <Trust />,
  },
  {
    path: "services/book-keeping",
    element: <Bookeeping />,
  },
  {
    path: "services/us-tax-returns-checklist",
    element: <Usreturns />,
  },
  {
    path: "services/canada-personal-tax-return-checklist",
    element: <Careturns />,
  },
  {
    path: "/thank-you",
    element: <Thankyou />,
  },
  {
    path: "*",
    element: <NotFound />,
  },

  // admin
  {
    path: "admin",
    element: <Login />,
  },
  {
    path: "admin/leads",
    element: (
      <ProtectedRoute>
        <Leads />
      </ProtectedRoute>
    ),
  },
]);

function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
