import { Disclosure } from "@headlessui/react";
import React from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
import Footer from "../../components/Footer/Footer";
import Header2 from "../../components/Header/Header2";
import FileTaxing from "../../components/popup/FileTaxing";
import ScrollTop from "../../components/scrollTop/ScrollTop";
import { returns } from "../../constants/returns";
import WhatsAppSvg from "../../svg/WhatsAppSvg";

function Careturns() {
  return (
    <>
      <ScrollTop />
      <div className="absolute top-0 left-0 right-0 z-10">
        <Header2 />
      </div>
      {/* <div className="fixed bottom-14 animate-bounce duration-700 ease-linear left-5 z-10">
        <WhatsAppSvg />
      </div> */}
      <FileTaxing />
      <div className="bg-about bg-bottom">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 max-w-7xl mx-auto pt-56 pb-32">
          <div className="col-span-1 p-5 lg:p-0">
            <p className="text-2xl lg:text-5xl lg:tracking-wide lg:leading-[55px] font-normal text-white">
              Personal Tax return Checklist
            </p>
            <p className="text-white mt-5 mb-16 leading-7">
              This is a general idea of what you need to bring in to complete
              your taxes. Please note, not all of these slips will be applicable
              to you. Please also provide us with a copy of your ID (drivers
              license, passport, etc).
            </p>
          </div>
          <div className="col-span-1"></div>
        </div>
        <div className="flex items-center justify-center pb-3 animate-bounce duration-300">
          <IoIosArrowDown size={28} className="text-white" />
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-5 lg:px-0 my-20">
        {returns.map((item) => (
          <div className="border border-gray-200 p-6 mt-5" key={item.title}>
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex text-gray-500 font-semibold w-full justify-between rounded text-left text-sm focus:outline-none">
                    <span className={`${open && "text-black"}`}>
                      {item.title}
                    </span>
                    {!open ? <AiOutlinePlus /> : <AiOutlineMinus />}
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm">
                    <p className="mt-5">
                      <ul className="list-disc space-y-3">
                        {item.desc.map((q) => (
                          <li key={q}>{q}</li>
                        ))}
                      </ul>
                    </p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        ))}

        <p className="mt-10 mb-5">
          <span className="font-medium">Other Deductions</span> – only if you
          are aware that you can deduct these expenses
        </p>
        <p className="mt-10 mb-5">
          <span className="font-medium">Moving Expenses</span> – If you moved
          more than 40 km for job relocation
        </p>
        <p className="mt-10 mb-5">
          <span className="font-medium">Tax Installments paid to CRA</span>
        </p>
        <p className="mt-10 mb-5">
          <span className="font-medium">Employment Expenses</span> - If you have
          a valid T200 form from your employer.
        </p>

        <p className="mt-10">
          Any other tax slips/deductions that you may deem necessary to prepare
          your tax returns that are not reportable.
        </p>
      </div>
      <Footer />
    </>
  );
}

export default Careturns;
