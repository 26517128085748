import React from "react";
import { IoIosArrowDown } from "react-icons/io";
import Footer from "../components/Footer/Footer";
import Header2 from "../components/Header/Header2";
import FileTaxing from "../components/popup/FileTaxing";
import ScrollTop from "../components/scrollTop/ScrollTop";
import WhatsAppSvg from "../svg/WhatsAppSvg";

const data = [
  {
    _id: 1,
    title: "SMALL BUSINESS WORKSHEET - USA",
    link: require("../assets/forms/Small-Business-Worksheet-USA.xlsx"),
  },
  {
    _id: 2,
    title: "SMALL BUSINESS WORKSHEET - CANADA",
    link: require("../assets/forms/Small-Business-Worksheet-Canada.xlsx"),
  },
  {
    _id: 3,
    title: "RENTAL WORKSHEET",
    link: require("../assets/forms/Rental-Worksheet.xlsx"),
  },
  {
    _id: 4,
    title: "MEDICAL CHARITABLE SHEET",
    link: require("../assets/forms/Medical-_-Charitable-Sheet-2021.docx"),
  },
];

function Form() {
  return (
    <>
      <ScrollTop />
      <div className="absolute top-0 left-0 right-0 z-10">
        <Header2 />
      </div>
      {/* <div className="fixed bottom-14 animate-bounce duration-700 ease-linear left-5 z-10">
        <WhatsAppSvg />
      </div> */}
      <FileTaxing />
      <div className="bg-about bg-bottom">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 max-w-7xl mx-auto pt-56 pb-32">
          <div className="col-span-1 px-5 lg:px-0">
            <p className="text-4xl lg:tracking-wider lg:leading-[55px] font-medium text-white">
              Forms
            </p>
            <p className="text-white mt-5 mb-16 leading-7">
              Maurice Morneau Tax was founded by reputed accounting and taxation
              experts over three decades ago. Since the time of our advent, we
              have strived towards excellence in providing nothing but the best
              accounting services.
            </p>
          </div>
          <div className="col-span-1"></div>
        </div>
        <div className="flex items-center justify-center pb-3 animate-bounce duration-300">
          <IoIosArrowDown size={28} className="text-white" />
        </div>
      </div>
      <div className="max-w-4xl mx-auto px-5 lg:px-0">
        {data?.map((a) => (
          <div
            className={`lg:flex space-y-5 lg:space-y-0 items-center justify-between py-10 border-gray-200 border_d`}
          >
            <p className="font-semibold text-gray-700">{a.title}</p>
            <a
              href={a.link}
              className="bg-gradient-to-r  from-[#3c53be] to-[#2c4699] rounded text-xs uppercase text-white px-5 py-2.5 font-semibold"
            >
              Download
            </a>
          </div>
        ))}
      </div>
      <Footer />
    </>
  );
}

export default Form;
