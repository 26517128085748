export const faqsData = [
  {
    _id: 1,
    question: "I have to file my tax return. What papers do I need?",
    answers: [
      "Is it your first time doing your taxes with us? If yes, please provide your full name, social insurance number, date of birth, address, phone number and email address. You may also provide a copy of your driver license, passport which has most of this information and also for our verification. ",
      "If you are currently a client, has any of your personal information (phone number, address, etc) changed? If so, please provide us with the updated information.",
      "Did you work last year? If so, you will need to provide T4’s from your employer(s).",
      "Did you receive unemployment, or any benefits from the government related to Covid-19? If the answer is yes you will have to provide a T4A or T4E which you will receive from the government.",
      "Did you receive any disability benefits, social assistance and workers compensation? If yes, you will need to provide a T5007, T4AP which you will receive from the government.",
      "Have you been approved to receive the Disability Tax Credit (DTC)? If yes, please provide the approval letter you received from the government or advise us that you have been approved for the DTC.",
      "Do you have withdrawn from any RRSP’s, RRIF’s, other investment income or Home Buyers Plan (HBP)? If yes, you will need to provide us with your T4RSP (RRSP), T4RIF (RRIF), T3, T5, or T5008 slips that you will receive from the bank.",
      "If you have HBP (Home Buyers Plan) repayment to the CRA, you must provide us with a copy of your latest notice of Assessment from the CRA for us to accurately calculate the HBP repayments.",
      "Did you sell any property last year? If so, please provide the date of purchase, purchase amount, date of sale and sale amount. Please also confirm if this is your principal residence or is it an investment property. If you have share in the property with your wife or someone else, please provide the percentage of your and your spouse ownership in that property.",
      "What is your marital status? If you are married or considered common-law please provide all above information for your spouse.",
      "Do you have any dependents? If yes, please provide us with their full name, date of birth, social insurance number and income (if applicable) ",
    ],
    note: [
      "*See below for a list of possible deductions*",
      "* If you worked at UBER, Skip the Dishes, Door Dash, etc. you are considered self-employed (Please see section relating to Self-Employed for more details) *",
    ],
  },
  {
    _id: 2,
    question: "I am a senior and I need to do my taxes. What papers do I need?",
    answers: [
      "For your Canada Pension Plan you will need to provide your T4A(P) (pink slip)",
      "For your Old Age Security, you will need to provide your T4A(OAS) (yellow slip)",
      "Did you receive any other pension last year? If so, please provide the T4A slip you have received.",
      "Did you receive a foreign pension last year? If so, please provide the tax slip/the amount of pension from which country you received",
      "Do you have income from RRSP’s, RRIF’s or other investment income? If yes, you will need to provide us with your T4RSP (RRSP), T4RIF (RRIF), T3, or T5 slips that you will receive from the bank.",
      "Did you receive any disability benefits? If yes, you will need to provide a T4AP which you will receive from the government.",
    ],
    note: [
      "*See below for a list of possible deductions* (Generally seniors claim property taxes/rent, donations and medical expenses)",
    ],
  },
  {
    _id: 3,
    question:
      "I am a student and I need to file my tax return. What papers do I need?",
    answers: [
      "Did you work last year? If yes, you will need T4 slips from your employer(s)",
      "Did you attend school last year? If yes you need a T2202 form from your educational institution. Tuition slip T2202 and T4A forms can be obtained from your educational institution website portal.",
      "Did you receive educational funds, bursaries, scholarships? If yes, please provide a copy for T4A that you may have received from your institution.",
      "Did you get a national student loan (not the line of credit from banks, National student loan is a government funded loan. National Student loan is not the line of credit from National Bank) last year? If so, Please provide a copy of the Interest deduction letter received from the National Student Loan Department of Canada.",
      "If this is your first year in Canada, please follow the next section (recent immigrant).",
    ],
    note: ["*See below for a list of possible deductions*"],
  },

  {
    _id: 4,
    question:
      "I am a recent immigrant to Canada and I need to file my tax return. What papers do I need?",
    answers: [
      "Please provide a copy of your Passport?",
      "What is your landing date? Please provide your landing documents if possible.",
      "What is your full legal name and date of birth?",
      "What is your Social Insurance Number?",
      "What is your current address in Canada?",
      "Did you work last year (If filing in 2021 last year means taxation year 2020)? If yes, you will need T4 slips from your employers",
      "Did you attend school last year? If yes, you will need your T2202 form from your educational institution",
      "What is your current marital status? If married, please provide the above details for your spouse as well as their income last year.",
      "Do you have any dependents? If so, please provide their full name, date of birth, social insurance number and their income (if applicable)",
    ],
    note: ["*See below for a list of possible deductions*"],
  },
  {
    _id: 5,
    question:
      "I am self-employed and I need to file my tax return. What papers do I need?",
    answers: [
      "Did you have income from being self-employed? ",
      "If yes, please fill out our small business worksheet and provide it to us.",
      "If you do not want to complete our small business worksheet, rather you would like us to do the totals of your income and expenses, there may be additional charges based on the amount of work and complexity. You may discuss this with our staff in our office.",
      "Did you work for a company as a regular employee in addition to being self-employed? If yes, please provide the T4 or T4A slip that you received from that company.",
      "Did you receive a loan from the bank? If so, please provide us with the loan forgiveness amount as this is taxable in your hand in the current year.",
      "Did you receive any money from the government relating to Covid-19? Please provide the T4A slip(s) you received from the government.",
    ],
    note: ["*See below for a list of possible deductions*"],
  },
  {
    _id: 6,
    question:
      "I have a rental property and I need to file my tax return. What papers do I need?",
    answers: [
      "Did you have income from your rental property? If so, please fill out our rental property worksheet.",
      "If you do not want to complete our rental property worksheet, rather you would like us to do the totals of your income and expenses, there may be additional charges based on the amount of work and complexity. You may discuss this with our staff in our office.",
      "Did you receive any money from the government relating to Covid-19? Please provide the T4A slip(s) you received from the government.",
      "Did you work last year? If so, please provide the T4 slips you received from your employer. If you were self-employed please see section on self-employment income.",
      "Did you sell your rental property last year? If so, please provide the date of purchase, purchase price, date of sale, and the sale price.",
      "Did you live in your rental property? If so, please provide the percentage of the property used for personal use.",
      "Do you have any RRSP’s, RRIF’s or other investment income? If yes, you will need to provide us with your T4RSP (RRSP), T4RIF (RRIF), T3, or T5 slips that you will receive from the bank.",
      "Please provide a total amount you spent on renovations, other capital improvements such as buying furnace, replacing windows, roofing, etc.",
    ],
    note: ["*See below for a list of possible deductions*"],
  },
  {
    _id: 7,
    question:
      "I have questions regarding Corporate Taxes, Bookkeeping, US Taxes, Final Tax Returns (Filing for death persons), Estates or Foreign Income. Can you help?",
    answers: [
      "Absolutely! Please contact our office at 519-253-7800 or send us an e-mail at  windsor@mauricemorneautax.com for more information or to get a quote.",
    ],
  },
];
