import React, { useState } from "react";
import Footer from "../components/Footer/Footer";
import { FaEnvelope, FaPhoneAlt, FaFax } from "react-icons/fa";
import Header2 from "../components/Header/Header2";
import FileTaxing from "../components/popup/FileTaxing";
import ScrollTop from "../components/scrollTop/ScrollTop";
// import WhatsAppSvg from "../svg/WhatsAppSvg";
import { useNavigate } from "react-router-dom";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase";

function Contact() {
  return (
    <>
      <ScrollTop />
      <div className="absolute top-0 left-0 right-0 z-10">
        <Header2 />
      </div>
      {/* <div className="fixed bottom-14 animate-bounce duration-700 ease-linear left-5 z-10">
        <WhatsAppSvg />
      </div> */}
      <FileTaxing />
      <img
        src={require("../assets/h25.jpg")}
        className="w-full"
        loading="lazy"
        alt=""
      />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div className="col-span-1">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2878.1270147945825!2d-79.26959128449637!3d43.832464279115634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4d7798bfb4e87%3A0xda7b1da230982ceb!2sMaurice%20Morneau%20Tax!5e0!3m2!1sen!2sin!4v1610110123361!5m2!1sen!2sin"
            title="location_2"
            height={"450px"}
            width={"100%"}
            allowFullScreen
          ></iframe>
        </div>
        <div className="col-span-1">
          <iframe
            title="location_1"
            height={"450px"}
            width={"100%"}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2951.1844293192853!2d-83.02859018467991!3d42.295929979191285!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b2c5743e5dd27%3A0xdfbd1de9516a73ae!2sMaurice%20Morneau%20Tax!5e0!3m2!1sen!2sin!4v1607349487614!5m2!1sen!2sin"
            allowFullScreen
          ></iframe>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 container mx-auto gap-5 py-20 px-5 lg:px-0">
        <div className="col-span-1">
          <Details />
        </div>
        <div className="col-span-2">
          <ContactForm />
        </div>
      </div>
      <Footer />
    </>
  );
}

const Details = () => {
  return (
    <>
      <div className="greetings">
        <p className="font-extrabold text-base capitalize mb-6">say hello</p>
        <p className="text-sm font-normal mb-6">
          Reach out to us on mail for any assistance regarding tax returns
          filings and more tax services.
        </p>
        <a href="mailto:bothwell@cktaxes.ca" className="text-sm font-medium">
          bothwell@cktaxes.ca
        </a>
      </div>

      <div className="gta_location mt-12">
        <p className="font-extrabold text-base capitalize mb-5">Bothwell</p>
        <p className="text-sm font-normal">
          30395 W Bothwel Rd, Bothwell, ON NOP 1C0
        </p>
        <a
          href="mailto:bothwell@cktaxes.ca"
          className="text-sm font-medium flex items-center mt-1"
        >
          <FaEnvelope className="mr-2" />
          bothwell@cktaxes.ca
        </a>

        <a
          href="tel:519-695-3425"
          className="text-sm font-medium flex items-center mt-1"
        >
          <FaPhoneAlt className="mr-2" />
          519-695-3425
        </a>

        <p className="text-sm font-medium flex items-center mt-1">
          <FaFax className="mr-2" />
          519-695-5087
        </p>
      </div>

      <div className="h-px bg-gray-200 opacity-75 w-full my-12"></div>

      <div className="windsor_location ">
        <p className="font-extrabold text-base capitalize mb-5">Windsor</p>
        <p className="text-sm font-normal">
          2115 Pelissier St, Windsor, ON N8X 1N3
        </p>
        <a
          href="mailto:windsor@mauricemorneautax.com"
          className="text-sm font-medium flex items-center mt-1"
        >
          <FaEnvelope className="mr-2" />
          windsor@mauricemorneautax.com
        </a>

        <a
          href="tel:519-253-7800"
          className="text-sm font-medium flex items-center mt-1"
        >
          <FaPhoneAlt className="mr-2" />
          519-253-7800
        </a>

        <p className="text-sm font-medium flex items-center mt-1">
          <FaFax className="mr-2" />
          519-253-7435
        </p>
      </div>
    </>
  );
};

const ContactForm = () => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [service, setService] = useState("");
  const [loader, setLoader] = useState(false);

  let navigate = useNavigate();

  const today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  var time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  var dateTime = date + " " + time;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (name !== "" && phone !== "" && service !== "") {
      setLoader(true);
      const dataCollectionRef = collection(db, "enquiries");
      try {
        await addDoc(dataCollectionRef, {
          name,
          email,
          phone,
          service,
          message,
          timestamp: dateTime,
        });
        setLoader(false);
        setName("");
        setEmail("");
        setPhone("");
        setService("");
        navigate("/thank-you");
      } catch (error) {
        console.log(error);
        // toast.error("Something went wrong!");
      }
      setLoader(false);
    } else {
      // toast.error("Please fill all the fields");
    }
  };

  return (
    <form className="space-y-5" onSubmit={handleSubmit}>
      <div className="grid md:grid-cols-2 gap-5">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Name <span className="text-red-600">*</span>
          </label>
          <input
            className="border h-10 outline-none px-3 rounded-md w-full focus:ring-red-500 focus:border-red-500"
            type="text"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Email <span className="text-red-600">*</span>
          </label>
          <input
            className="border h-10 outline-none px-3 rounded-md w-full focus:ring-red-500 focus:border-red-500"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Phone
          </label>
          <input
            className="border h-10 outline-none px-3 rounded-md w-full focus:ring-red-500 focus:border-red-500"
            type="text"
            minLength="10"
            maxLength="10"
            required
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
      </div>
      <div>
        <label htmlFor="">
          Select a tax type? <span className="text-red-600">*</span>
        </label>
        <div class="lg:flex items-center lg:space-x-7 mt-2">
          <div class="space-x-1">
            <input
              type="radio"
              id="Canadian"
              name="tax_type"
              onChange={(e) => setService(e.target.value)}
            />
            <label for="Canadian">Canadian personal tax</label>
          </div>
          <div class="space-x-1">
            <input
              type="radio"
              id="us_tax"
              name="tax_type"
              onChange={(e) => setService(e.target.value)}
            />
            <label for="us_tax">US tax</label>
          </div>
          <div class="space-x-1">
            <input
              type="radio"
              name="tax_type"
              id="c_tax"
              onChange={(e) => setService(e.target.value)}
            />
            <label for="c_tax">Corporate tax</label>
          </div>
          <div class="space-x-1">
            <input
              type="radio"
              id="nr_tax"
              name="tax_type"
              onChange={(e) => setService(e.target.value)}
            />
            <label for="nr_tax">Non-resident tax</label>
          </div>
          <div class="space-x-1">
            <input
              type="radio"
              id="CRA"
              name="tax_type"
              onChange={(e) => setService(e.target.value)}
            />
            <label for="CRA">CRA/IRS notice and others</label>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <label htmlFor="message">
          Your Message <span className="text-red-600">*</span>
        </label>
        <textarea
          name=""
          id=""
          rows="8"
          className="outline-none focus:outline-none border resize-none mt-1"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
      </div>

      <button
        type="submit"
        className="bg-gradient-to-l rounded from-[#2c4699] to-[#3c53be] uppercase text-white px-5 py-2.5 font-bold"
      >
        submit
      </button>
    </form>
  );
};

export default Contact;
