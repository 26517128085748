import { Disclosure } from "@headlessui/react";
import React from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
import Footer from "../../components/Footer/Footer";
import Header2 from "../../components/Header/Header2";
import FileTaxing from "../../components/popup/FileTaxing";
import ScrollTop from "../../components/scrollTop/ScrollTop";
import WhatsAppSvg from "../../svg/WhatsAppSvg";

function Usreturns() {
  return (
    <>
      <ScrollTop />
      <div className="absolute top-0 left-0 right-0 z-10">
        <Header2 />
      </div>
      {/* <div className="fixed bottom-14 animate-bounce duration-700 ease-linear left-5 z-10">
        <WhatsAppSvg />
      </div> */}
      <FileTaxing />
      <div className="bg-about bg-bottom">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 max-w-7xl mx-auto pt-56 pb-32">
          <div className="col-span-1 p-5 lg:p-0">
            <p className="text-2xl lg:text-5xl lg:tracking-wide lg:leading-[55px] font-normal text-white">
              US Tax Returns Checklist
            </p>
            <p className="text-white mt-5 mb-16 leading-7">
              This is a general idea of what you need to bring in to complete
              your taxes. Please note, not all of these slips will be applicable
              to you.
            </p>
          </div>
          <div className="col-span-1"></div>
        </div>
        <div className="flex items-center justify-center pb-3 animate-bounce duration-300">
          <IoIosArrowDown size={28} className="text-white" />
        </div>
      </div>
      <div className="max-w-7xl mx-auto px-5 lg:px-0 my-20">
        <div className="border border-gray-200 p-6 mt-5">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex text-gray-500 font-semibold w-full justify-between rounded text-left text-sm focus:outline-none">
                  <span className={`${open && "text-black"}`}>
                    Personal Identification Information
                  </span>
                  {!open ? <AiOutlinePlus /> : <AiOutlineMinus />}
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm">
                  <p className="mt-5">
                    <ul className="list-none space-y-2">
                      <li>
                        1. SSN numbers, Date of Birth for all your family
                        members
                      </li>
                      <li>2. A copy of your last year tax return</li>
                      <li>
                        3. If you would like the refund deposited to your bank
                        account, please provide us with your bank account
                        details such as routing number and account number.
                      </li>
                    </ul>
                  </p>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>

        <div className="border border-gray-200 p-6 mt-5">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex text-gray-500 font-semibold w-full justify-between rounded text-left text-sm focus:outline-none">
                  <span className={`${open && "text-black"}`}>
                    Tax Information for yourself and your spouse
                  </span>
                  {!open ? <AiOutlinePlus /> : <AiOutlineMinus />}
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm">
                  <p className="mt-5">
                    <ul className="list-none space-y-2">
                      <li>1. Copies of W-2</li>
                      <li>
                        2. 1099-G, 1099-DIV, 1099-INT, 1099-Misc, 1099-C,
                        1099-R, K-1s, 1099-B, 1099-B, SSA-1099, 1099-IRA, any
                        other tax slips you may have received.
                      </li>
                      <li>
                        3. If you have business or farming income, please
                        provide us with the profit and loss statement. You may
                        use our small business worksheet for your convenience.
                      </li>
                      <li>
                        4. Rental Income – Provide us with rental income and
                        expenses by each unit/property that you own.
                      </li>
                      <li>
                        5. Any other miscellaneous income, that was not recorded
                        above.
                      </li>
                      <li>
                        6. For claiming Student loan interest paid, provide us
                        with Form 1098-E
                      </li>
                      <li>7. Tuition claim – Form 1098-T</li>
                      <li>8. Alimony received or paid amounts</li>
                      <li>
                        9. For claiming child care expenses, you will have to
                        provide us with the copy of the receipt from the child
                        care detailing their address and EIN number.
                      </li>
                      <li>
                        10. Tax installments paid to the IRS and state tax
                        departments for the year.
                      </li>
                      <li>
                        11. If you would like to claim itemized deductions,
                        please provide us with the mortgage interest amount/1099
                        mortgage slip, property taxes you paid in the year,
                        Investment administration costs and charitable
                        donations.
                      </li>
                    </ul>
                  </p>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>

        <p className="mt-10">
          *Please note that this is not an elaborate list of tax information.
          This list is for your reference, you may be having more or less slips
          from the list mentioned above.
        </p>
      </div>
      <Footer />
    </>
  );
}

export default Usreturns;
