import React from "react";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header2 from "../../components/Header/Header2";
import FileTaxing from "../../components/popup/FileTaxing";
import ScrollTop from "../../components/scrollTop/ScrollTop";
import WhatsAppSvg from "../../svg/WhatsAppSvg";

function Bookeeping() {
  return (
    <>
      <ScrollTop />
      <div className="absolute top-0 left-0 right-0 z-10">
        <Header2 />
      </div>
      {/* <div className="fixed bottom-14 animate-bounce duration-700 ease-linear left-5 z-10">
        <WhatsAppSvg />
      </div> */}
      <FileTaxing />
      <div className="bg-about bg-bottom">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 max-w-7xl mx-auto pt-56 pb-32">
          <div className="col-span-1 p-5 lg:p-0">
            <p className="text-2xl lg:text-4xl lg:tracking-wider lg:leading-[55px] font-semibold text-white">
              Book Keeping
            </p>
            <p className="text-white mt-5 mb-16">
              Maurice Morneau Tax was founded by reputed accounting and taxation
              experts over three decades ago. Since the time of our advent, we
              have strived towards excellence in providing nothing but the best
              accounting services.
            </p>
            <p>
              <Link
                to={"/"}
                className="text-xs bg-gradient-to-l rounded from-[#2c4699] to-[#3c53be] uppercase text-white px-5 py-2.5 font-semibold"
              >
                Learn More
              </Link>
            </p>
          </div>
          <div className="col-span-1"></div>
        </div>
        <div className="flex items-center justify-center pb-3 animate-bounce duration-300">
          <IoIosArrowDown size={28} className="text-white" />
        </div>
      </div>

      <div className="py-20 max-w-7xl mx-auto px-5 lg:px-0">
        <p className="lg:text-4xl text-2xl font-medium">
          Let us take care of the paperwork.
        </p>
        <p className="mt-14 mb-5">
          Operating a successful business can be incredibly time consuming. It’s
          important to recognize that your time has value and when you are
          spending your hours trying to balance your books, or worrying about
          government remittances and payroll, something has to give. There are
          better uses for your time. That’s why hiring a professional bookkeeper
          is essential. Let us take care of the paperwork. We will make sure
          that your business financials are organized, accurate, and reconciled
          expediently. Having an experienced set of eyes looking at your
          business can also provide an early warning to potential problems ahead
          and an organized set of books makes it easier to see exactly where
          your money is going. Tax season is a breeze when you’re organized!
        </p>
        {/* <p>Bookkeeping services – Starting from $85 + HST</p> */}
        <p>Payroll services – Starting from $50 + HST</p>
      </div>

      <Footer />
    </>
  );
}

export default Bookeeping;
