import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { IoIosCloseCircle } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase";
import { CgSpinner } from "react-icons/cg";

function FileTaxing() {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [service, setService] = useState("");
  const [loader, setLoader] = useState(false);

  let navigate = useNavigate();

  const today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  var time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  var dateTime = date + " " + time;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (name !== "" && phone !== "" && service !== "") {
      setLoader(true);
      const dataCollectionRef = collection(db, "enquiries");
      try {
        await addDoc(dataCollectionRef, {
          name,
          email,
          phone,
          service,
          timestamp: dateTime,
        });
        setLoader(false);
        setName("");
        setEmail("");
        setPhone("");
        setService("");
        navigate("/thank-you");
      } catch (error) {
        console.log(error);
        // toast.error("Something went wrong!");
      }
      setLoader(false);
    } else {
      // toast.error("Please fill all the fields");
    }
  };

  return (
    <>
      <div className="relative">
        <button
          className="uppercase bg-[#1F3B78] px-5 py-2.5 fixed top-1/2 -right-20 -rotate-90 text-white font-medium outline-none z-20"
          onClick={(e) => setOpen(!open)}
        >
          Start Filing taxes
        </button>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="text-left">
                    <button
                      className="outline-none absolute right-2 top-2"
                      onClick={() => setOpen(false)}
                    >
                      <IoIosCloseCircle size={28} />
                    </button>
                    <form className="mt-2 space-y-3" onSubmit={handleSubmit}>
                      <h4 className="text-center text-[#2c4699] font-bold">
                        Expert Tax Filing Service
                      </h4>

                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Name <span className="text-red-600">*</span>
                        </label>
                        <input
                          className="border h-10 outline-none px-3 rounded-md w-full "
                          type="text"
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Email <span className="text-red-600">*</span>
                        </label>
                        <input
                          className="border h-10 outline-none px-3 rounded-md w-full "
                          type="email"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Phone
                        </label>
                        <input
                          className="border h-10 outline-none px-3 rounded-md w-full "
                          type="text"
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Select a tax service
                          <span className="text-red-600">*</span>
                        </label>
                        <select onChange={(e) => setService(e.target.value)} className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none  sm:text-sm">
                          <option> --Select-- </option>
                          <option>Accounting Services</option>
                          <option>Corporate Income Taxes</option>
                          <option>Cross Border Taxes</option>
                          <option>Non-Resident Taxes</option>
                          <option>US Taxes</option>
                          <option>Taxes for deceased</option>
                          <option>Book Keeping Services</option>
                          <option>Other</option>
                        </select>
                      </div>

                      <button
                        type="submit"
                        className="w-full bg-gradient-to-l from-[#2c4699] to-[#3c53be] rounded-md border shadow-sm py-2 mb-3 hover:opacity-95 font-medium text-white text-sm"
                      >
                        {loader ? (
                <div className="flex items-center justify-center">
                  <CgSpinner className="animate-spin h-5 mr-2 text-white w-5" />
                  Loading
                </div>
              ) : (
                "SUBMIT"
              )}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default FileTaxing;
