import React from "react";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import Header2 from "../components/Header/Header2";
import FileTaxing from "../components/popup/FileTaxing";
import ScrollTop from "../components/scrollTop/ScrollTop";

const data = [
  {
    _id: 1,
    title: "Personal Income Taxes",
    link: "/services/personal-income-tax-returns",
    img: require("../assets/New-Project.png"),
  },
  {
    _id: 2,
    title: "Corporate Income Taxes",
    link: "/services/corporate-income-tax-returns",
    img: require("../assets/tax-returns-1.png"),
  },
  {
    _id: 3,
    title: "Non Resident Taxes",
    link: "/services/non-resident-taxes",
    img: require("../assets/tax-returns-5.png"),
  },
  {
    _id: 4,
    title: "Cross Border Taxes",
    link: "/services/cross-border-taxes",
    img: require("../assets/tax-returns-2.png"),
  },
  {
    _id: 5,
    title: "US Federal and State Taxes",
    link: "/services/us-federal-and-state-taxes",
    img: require("../assets/tax-returns-4.png"),
  },
  {
    _id: 6,
    title: "Trust Taxes for Deceased Individuals",
    link: "/services/trust-taxes-for-deceased-individuals",
    img: require("../assets/tax-returns-3.png"),
  },
  {
    _id: 7,
    title: "Book Keeping",
    link: "/services/book-keeping",
    img: require("../assets/tax-returns-1.png"),
  },
];

function Services() {
  return (
    <>
      <ScrollTop />
      <div className="absolute top-0 left-0 right-0 z-10">
        <Header2 />
      </div>
      {/* <div className="fixed bottom-14 animate-bounce duration-700 ease-linear left-5 z-10">
        <WhatsAppSvg />
      </div> */}
      <FileTaxing />
      <div className="bg-about bg-bottom">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 max-w-7xl mx-auto pt-56 pb-32">
          <div className="col-span-1">
            <p className="text-4xl tracking-wider leading-[55px] font-semibold text-white">
              Tax and Accounting Services
            </p>
            <p className="text-white mt-5 mb-16">
              Maurice Morneau Tax was founded by reputed accounting and taxation
              experts over three decades ago. Since the time of our advent, we
              have strived towards excellence in providing nothing but the best
              accounting services.
            </p>
            <p>
              <Link
                to={"/"}
                className="bg-gradient-to-l rounded from-[#2c4699] to-[#3c53be] uppercase text-white px-5 py-2.5 font-bold"
              >
                about us
              </Link>
            </p>
          </div>
          <div className="col-span-1"></div>
        </div>
        <div className="flex items-center justify-center pb-3 animate-bounce duration-300">
          <IoIosArrowDown size={28} className="text-white" />
        </div>
      </div>
      <div className="relative">
        <div className="bg-[#eedfcc] h-96 ">
          <div className="container mx-auto px-5 lg:px-0">
            <p className="pt-20 text-3xl">Check out our list of services.</p>
          </div>
        </div>
        <div className="container mx-auto mb-10">
          <div className="-mt-48">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-10">
              {data?.map((a) => (
                <div
                  className="col-span-1 overflow-hidden relative"
                  key={a._id}
                >
                  <Link to={a.link}>
                    <div className="bg-black h-96">
                      <img
                        loading="lazy"
                        className="object-cover w-full pit_img"
                        src={a.img}
                        alt=""
                      />
                      <section className="absolute top-0 text-white p-10">
                        <p className="text-2xl font-semibold">{a.title}</p>
                      </section>
                      <section className="absolute bottom-0 text-white p-10">
                        <div className="rounded-full border border-gray-300 p-3 py-3 arrow_bg">
                          <IoIosArrowForward />
                        </div>
                      </section>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Services;
