import React from "react";

function NotFound() {
  return (
    <>
      <p className="text-7xl font-semibold text-center mt-32">404</p>
      <p className="text-5xl font-semibold text-center mb-32">Not Found</p>
    </>
  );
}

export default NotFound;
