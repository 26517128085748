export const personal_taxes = [
  {
    _id: 1,
    title: "EXPERT TIPS TO SAVE MORE",
    desc: "We also provide you with expert tips and advice to help you save on your tax liabilities for future years.",
  },
  {
    _id: 2,
    title: "ASSISTING YOU SINCE THE BEGINNING",
    desc: "At Maurice Morneau Tax, we have years of experience in helping our clients file their personal income tax returns. Every year many 1,000’s of taxpayers do their taxes at our office. This had been achieved with strategically priced fees and friendly service",
  },
  {
    _id: 3,
    title: "QUALITY SERVICE WITH TAX GUIDANCE",
    desc: "Our specialized tax experts make your visit more valuable by providing a high quality of service and tax guidance that is easy to understand.",
  },
];

export const corporate_taxes = [
  {
    _id: 1,
    title: "GAIN EVERY ADVANTAGE POSSIBLE",
    desc: "We make sure that our clients are well aware of the various employer incentives, subsidies and tax credits available at the different government levels, so that they can gain every advantage possible. At Maurice Morneau Tax, we take care of the details so that you can focus on growing your business!",
  },
  {
    _id: 2,
    title: "SERVING BUSINESSES WITH THE BEST TAX SERVICES",
    desc: "Apart from providing unmatched income tax services, we specialize in assisting our clients with the preparation of financial statements along with the filing of their Corporate Income Tax Returns in both Canada and the USA.",
  },
  {
    _id: 3,
    title: "KEEPING OUR CLIENTS UPDATED",
    desc: "We make sure to keep well versed on the frequent regulatory changes in the tax law, and to update our clients with the same information.",
  },
];

export const trust_taxes = [
  {
    _id: 1,
    title: "RELIABLE AND EFFICIENT TRUST TAX PLANNERS",
    desc: "We have years of experience and expertise in dealing with intricate trust accounts. Making sure that none of our clients have to bear the extra stress while already dealing with the pain of losing their loved ones is our priority",
  },
  {
    _id: 2,
    title: "WE MAKE IT SIMPLER",
    desc: "We make the entire process simple, and ensure that you are in capable hands when it comes to finalizing the estate taxes for those who have passed away.",
  },
];

export const us_taxes = [
  {
    _id: 1,
    title: "WE COMPLY WITH IRS REQUIREMENTS",
    desc: "Without a doubt, this is a difficult and grueling task, especially for those who live outside the country. It is extremely important to hire tax professional experts you can trust to take care of your US Tax requirements, allowing you to comply with the IRS requirements. Let us help you!",
  },
  {
    _id: 2,
    title: "HIGH LEVEL OF QUALITY SERVICES",
    desc: "We have made a mark for ourselves by ensuring that all of our clients receive a high level of quality service when dealing with US Taxes. Our sole aim is to provide our clients with comprehensive solutions to all of their US Tax dilemmas. We are able to e-file US tax returns in our Canadian office where applicable.",
  },
];

export const cross_border_taxes = [
  {
    _id: 1,
    title: "EXPAND THE SCOPE OF YOUR BUSINESS",
    desc: "In this day and age, the world is a global market, and every other country works in an open economy. However, the difficulty arises when cross border tax implications get involved. This is why, to expand the scope of your business in the global arena, it is important that you hire expert tax consultants who can not only assist you with effective cross-border tax solutions, but also guide you efficiently throughout the tenure of your venture.",
  },
  {
    _id: 2,
    title: "THE NAME YOU CAN TRUST",
    desc: "With over 3 decades in the accounting industry, we have gained invaluable experience in providing solutions for all cross-border tax concerns.",
  },
  {
    _id: 3,
    title: "HASSLE FREE CLIENT EXPERIENCES",
    desc: "Our experts are always up to date on the ever-changing tax regulations and how to implement them effectively for the further advancement of your business. This is why we are the preferred choice for our clients. We are driven to ensure that our clients experience with cross border taxes is always hassle free.",
  },
];

export const non_resident = [
  {
    _id: 1,
    title: "GUIDANCE OF TAX COMPLIANCE",
    desc: "If you are leaving Canada and will have Canadian income/properties our experts can help you comply with Canadian tax laws while you are away from Canada. Similarly, if you are entering Canada and have properties outside Canada, we will provide guidance on tax compliance requirements.",
  },
  {
    _id: 2,
    title: "YOUR EXPERT ASSISTANTS FOR NON-RESIDENT TAXES",
    desc: "We here at Maurice Morneau Tax understand the difficulties that you face as a non-resident. We also understand how overwhelming it can be for you to file your income taxes in this new setting. Let us help you in assessing your liabilities, filing the necessary declarations and returns, and helping you with your entry and exit strategies",
  },
  {
    _id: 3,
    title: "USING TECHNOLOGY TO COMMUNICATE WITH CLIENTS",
    desc: "We will ensure that your Canadian Tax requirements are met. We are new-age accountants, we use technology to communicate with virtual clients to obtain information and provide consultations, this will limit the requirement of meeting the accountant in person.",
  },
];
