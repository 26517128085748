import { Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { HiBars3BottomRight } from "react-icons/hi2";
import { Link } from "react-router-dom";
import MobileHeader from "./MobileHeader";

const navigation = {
  services: [
    {
      _id: 1,
      name: "Personal Income Tax",
      href: "/services/personal-income-tax-returns",
    },
    {
      _id: 2,
      name: "Corporate Income Tax",
      href: "/services/corporate-income-tax-returns",
    },
    {
      _id: 3,
      name: "Non Resident Taxes",
      href: "/services/non-resident-taxes",
    },
    {
      _id: 4,
      name: "Cross Border Taxes",
      href: "/services/cross-border-taxes",
    },
    {
      _id: 5,
      name: "US Federal and State Taxes",
      href: "/services/us-federal-and-state-taxes",
    },
    {
      _id: 6,
      name: "Trust Taxes for Deceased Individuals",
      href: "/services/trust-taxes-for-deceased-individuals",
    },
    {
      _id: 7,
      name: "Book Keeping",
      href: "/services/book-keeping",
    },
  ],
  pages: [
    { name: "home", href: "/" },
    { name: "about", href: "/about-us" },
    { name: "forms", href: "/forms" },
    { name: "faq", href: "/frequently-asked-questions" },
    { name: "contact", href: "/contact" },
  ],
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Header2() {
  const [open, setOpen] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const fixedNavbar = () => {
    if (window.scrollY >= 100) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", fixedNavbar);

  return (
    <div
      className={`
        ${
          !navbar
            ? "bg-transparent"
            : "bg-white fixed top-0 z-20 w-full px-0 shadow-2xl drop-shadow-2xl "
        } duration-200
          `}
    >
      {/* desktop menu */}
      <header className="relative hidden lg:block">
        <div className="border-b-[1px] border-gray-600">
          <nav
            aria-label="Top"
            className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
          >
            <div className="flex h-28 items-center">
              {/* Logo */}
              <div className="ml-4 flex lg:ml-0">
                <Link to={"/"}>
                  <span className="sr-only">Maurice Morneau Tax and Accounting</span>
                  {!navbar ? (
                    <img
                      className="h-24 w-auto"
                      loading="lazy"
                      src={require("../../assets/MM-Tax-01.png")}
                      alt=""
                    />
                  ) : (
                    <img
                      className="h-24 w-auto"
                      loading="lazy"
                      src={require("../../assets/MM-Tax-02.png")}
                      alt=""
                    />
                  )}
                </Link>
              </div>

              <div className="ml-auto flex items-center">
                {/* Flyout menus */}
                <Popover.Group className=" lg:ml-8 lg:block lg:self-stretch">
                  <div className="flex h-full space-x-8">
                    {navigation.pages.slice(0, 2).map((page) => (
                      <a
                        key={page.name}
                        href={page.href}
                        className={`${
                          !navbar
                            ? "text-white hover:text-gray-300 font-medium"
                            : "text-[#090909] font-semibold"
                        } flex items-center text-sm uppercase`}
                      >
                        {page.name}
                      </a>
                    ))}

                    <Popover className="flex">
                      {({ open }) => (
                        <>
                          <div className="relative flex">
                            <Popover.Button
                              className={classNames(
                                open
                                  ? "border-indigo-600 text-indigo-600"
                                  : `${
                                      !navbar
                                        ? "text-white hover:text-gray-300 font-medium"
                                        : "text-[#090909] font-semibold"
                                    } border-transparent `,
                                "relative z-10 -mb-px flex items-center uppercase border-b-2 pt-px text-sm  transition-colors duration-200 ease-out focus:outline-none"
                              )}
                            >
                              Services
                            </Popover.Button>
                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Popover.Panel className="absolute top-full -ml-24 text-sm text-gray-100 shadow rounded overflow-hidden w-full max-w-[290px]">
                              <div
                                className="absolute inset-0 top-1/2"
                                aria-hidden="true"
                              />
                              {navigation.services.map((item) => (
                                <a href={item.href} key={item._id}>
                                  <p className="relative p-3 hover:bg-gray-900 bg-black z-10">
                                    {item.name}
                                  </p>
                                </a>
                              ))}
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>

                    {navigation.pages.slice(2, 5).map((page) => (
                      <a
                        key={page.name}
                        href={page.href}
                        className={`${
                          !navbar
                            ? "text-white hover:text-gray-300 font-medium"
                            : "text-[#090909] font-semibold"
                        } flex items-center text-sm uppercase`}
                      >
                        {page.name}
                      </a>
                    ))}
                  </div>
                </Popover.Group>
              </div>
            </div>
          </nav>
        </div>
      </header>

      <div className="lg:hidden ">
        <div className="flex items-center justify-between p-5 border-b-[1px] border-gray-600">
          {/* Logo */}
          <Link to={"/"}>
            <span className="sr-only">Maurice Morneau Tax and Accounting</span>
            {!navbar ? (
              <img
                className="h-24 w-auto"
                loading="lazy"
                src={require("../../assets/MM-Tax-01.png")}
                alt=""
              />
            ) : (
              <img
                className="h-24 w-auto"
                loading="lazy"
                src={require("../../assets/MM-Tax-02.png")}
                alt=""
              />
            )}
          </Link>
          <button
            type="button"
            className={!navbar ? "text-gray-200" : "text-black"}
            onClick={() => setOpen(true)}
          >
            <span className="sr-only">Open menu</span>
            <HiBars3BottomRight className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>

        <MobileHeader open={open} setOpen={setOpen} />
      </div>
    </div>
  );
}

export default Header2;
