/* eslint-disable jsx-a11y/no-redundant-roles */
import { Dialog, Tab, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'
import { HiXMark } from 'react-icons/hi2'
import { Link } from 'react-router-dom';

function MobileHeader({open, setOpen}) {
  const navigation = {
    services: [
      {
        _id: 1,
        name: "Personal Income Tax",
        href: "/services/personal-income-tax-returns",
      },
      {
        _id: 2,
        name: "Corporate Income Tax",
        href: "/services/corporate-income-tax-returns",
      },
      {
        _id: 3,
        name: "Non Resident Taxes",
        href: "/services/non-resident-taxes",
      },
      {
        _id: 4,
        name: "Cross Border Taxes",
        href: "/services/cross-border-taxes",
      },
      {
        _id: 5,
        name: "US Federal and State Taxes",
        href: "/services/us-federal-and-state-taxes",
      },
      {
        _id: 6,
        name: "Trust Taxes for Deceased Individuals",
        href: "/services/trust-taxes-for-deceased-individuals",
      },
      {
        _id: 7,
        name: "Book Keeping",
        href: "/book-keeping",
      },
    ],
    pages: [
      { name: "home", href: "/" },
      { name: "about", href: "/about-us" },
      { name: "forms", href: "/forms" },
      { name: "faq", href: "/frequently-asked-questions" },
      { name: "contact", href: "/contact" },
    ],
  };

  return (
    <>
     {/* Mobile menu */}
     <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                <div className="flex px-4 pt-5 pb-2">
                  <button
                    type="button"
                    className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <HiXMark className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Links */}
                <Tab.Group as="div" className="mt-2">
                  <Tab.Panels as={Fragment}>
                    
                      <Tab.Panel
                        className="space-y-10 px-4 pt-10 pb-8"
                      >

                          <div>
                            <p
                              className="font-medium text-gray-900"
                            >
                              Services
                            </p>
                            <ul
                              role="list"
                              className="mt-6 flex flex-col space-y-6"
                            >
                              {navigation.services.map((item) => (
                                <li key={item.name} className="flow-root">
                                  <Link
                                    to={item.href}
                                    className="-m-2 block p-2 text-gray-500 capitalize"
                                  >
                                    {item.name}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                      </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>

                <div className="space-y-6 border-t border-gray-200 py-6 px-4">
                  {navigation.pages.map((page) => (
                    <div key={page.name} className="flow-root">
                      <Link
                        to={page.href}
                        className="-m-2 block p-2 font-medium text-gray-900 capitalize"
                      >
                        {page.name}
                      </Link>
                    </div>
                  ))}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default MobileHeader