import { Disclosure } from "@headlessui/react";
import React from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
import Footer from "../../components/Footer/Footer";
import Header2 from "../../components/Header/Header2";
import FileTaxing from "../../components/popup/FileTaxing";
import ScrollTop from "../../components/scrollTop/ScrollTop";
import { cross_border_taxes } from "../../constants/taxes";
import WhatsAppSvg from "../../svg/WhatsAppSvg";

function CrossBorder() {
  return (
    <>
      <ScrollTop />
      <div className="absolute top-0 left-0 right-0 z-10">
        <Header2 />
      </div>
      {/* <div className="fixed bottom-14 animate-bounce duration-700 ease-linear left-5 z-10">
        <WhatsAppSvg />
      </div> */}
      <FileTaxing />
      <div className="bg-about bg-bottom">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 max-w-7xl mx-auto pt-56 pb-32">
          <div className="col-span-1 p-5 lg:p-0">
            <p className="text-2xl lg:text-5xl lg:tracking-wide lg:leading-[55px] font-normal text-white">
              Simplifying the Implications of Cross-Border Taxes
            </p>
            <p className="text-white mt-5 mb-16">
              We are experts in Cross-border personal and corporate tax matters.
            </p>
            <p>
              <a
                href={"#cb"}
                className="text-xs bg-gradient-to-l rounded from-[#19cda0] to-[#38ef81] uppercase text-white px-5 py-2.5 font-semibold"
              >
                Learn More
              </a>
            </p>
          </div>
          <div className="col-span-1"></div>
        </div>
        <div className="flex items-center justify-center pb-3 animate-bounce duration-300">
          <IoIosArrowDown size={28} className="text-white" />
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2" id="cb">
        <div className="lg:hidden block col-span-1">
          <img
            src={require("../../assets/crossborder.jpg")}
            loading="lazy"
            className="w-full h-full object-cover"
            alt=""
          />
        </div>
        <div className="col-span-1 bg-slate-50 p-10 lg:px-24 lg:py-32">
          <p className="text-2xl lg:text-5xl font-medium text-[#1d3a6d]">
            Cross Border Taxes
          </p>
          <p className="text-gray-600 py-6">
            Are you crossing the border for work? Do you have businesses in both
            Canada and the USA? Do you sell your Canadian products in the States
            through online marketing? We are experts in Cross-border personal
            and corporate tax matters.
          </p>
          {cross_border_taxes?.map((item) => (
            <div className="border border-gray-200 p-6 mt-5">
              <Disclosure key={item._id}>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex text-gray-500 font-semibold w-full justify-between rounded text-left text-sm focus:outline-none">
                      <span className={`${open && "text-black"}`}>
                        {item.title}
                      </span>
                      {!open ? <AiOutlinePlus /> : <AiOutlineMinus />}
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm">
                      <p className="mt-5">{item.desc}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          ))}

          <p className="text-gray-600 leading-6 mt-5">
            We provide experience and attention to detail. If you are looking
            for guidance and assistance with your tax liabilities, please get in
            touch with us today! We promise to never let you down!
          </p>
        </div>
        <div className="lg:block hidden col-span-1">
          <img
            src={require("../../assets/crossborder.jpg")}
            loading="lazy"
            className="w-full h-full object-cover"
            alt=""
          />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CrossBorder;
