import React from "react";
import { TiTick } from "react-icons/ti";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header2 from "../Header/Header2";

function Thankyou() {
  return (
    <>
      {/* <Header2 /> */}
      <div className="flex flex-col items-center justify-center py-64">
        <TiTick className="text-9xl text-green-500" />
        <div className="text-center">
          <h1 className="text-3xl font-bold">Thank you for your interest!</h1>
          <p className="text-lg">
            We will get back to you as soon as possible.
          </p>
        </div>

        <div className="flex items-center justify-center space-y-5">
            <Link to={'/'} className="uppercase font-normal text-white bg-[#23a7e1] px-4 py-2.5 rounded-full mt-10">Return to Home</Link>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}

export default Thankyou;
