import { Disclosure } from "@headlessui/react";
import React from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
import Footer from "../../components/Footer/Footer";
import Header2 from "../../components/Header/Header2";
import FileTaxing from "../../components/popup/FileTaxing";
import ScrollTop from "../../components/scrollTop/ScrollTop";
import { corporate_taxes } from "../../constants/taxes";
import WhatsAppSvg from "../../svg/WhatsAppSvg";

function Corporate() {
  return (
    <>
      <ScrollTop />
      <div className="absolute top-0 left-0 right-0 z-10">
        <Header2 />
      </div>
      {/* <div className="fixed bottom-14 animate-bounce duration-700 ease-linear left-5 z-10">
        <WhatsAppSvg />
      </div> */}
      <FileTaxing />
      <div className="bg-about bg-bottom">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 max-w-7xl mx-auto pt-56 pb-32">
          <div className="col-span-1 p-5 lg:p-0">
            <p className="text-2xl lg:text-5xl lg:tracking-wide lg:leading-[55px] font-normal text-white">
              We will take care of A to Z’s of Your Company’s Accounting
            </p>
            <p className="text-white mt-5 mb-16">
              This Will Leave Your Mind To Focus On Growing Your Business.
            </p>
            <p>
              <a
                href={"#corporate"}
                className="text-xs bg-gradient-to-l rounded from-[#19cda0] to-[#38ef81] uppercase text-white px-5 py-2.5 font-semibold"
              >
                Learn More
              </a>
            </p>
          </div>
          <div className="col-span-1"></div>
        </div>
        <div className="flex items-center justify-center pb-3 animate-bounce duration-300">
          <IoIosArrowDown size={28} className="text-white" />
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2">
        <div className="lg:hidden block col-span-1">
          <img
            src={require("../../assets/corporate-1.jpg")}
            loading="lazy"
            className="w-full h-full object-cover"
            alt=""
          />
        </div>
        <div
          className="col-span-1 bg-slate-50 p-10 lg:px-24 lg:py-32"
          id="corporate"
        >
          <p className="text-2xl lg:text-5xl font-medium text-[#1d3a6d]">
            Corporate Income Tax Returns
          </p>
          <p className="text-gray-600 py-6">
            Corporate tax is one of the more complex areas of taxation. A small
            error can cost a business a considerable amount of money in fines
            and penalties, but also its reputation and goodwill gained over the
            years. That’s why hiring an experienced professional is very
            important.
          </p>
          {corporate_taxes?.map((item) => (
            <div className="border border-gray-200 p-6 mt-5">
              <Disclosure key={item._id}>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex text-gray-500 font-semibold w-full justify-between rounded text-left text-sm focus:outline-none">
                      <span className={`${open && "text-black"}`}>
                        {item.title}
                      </span>
                      {!open ? <AiOutlinePlus /> : <AiOutlineMinus />}
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm">
                      <p className="mt-5">{item.desc}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          ))}

          <p className="text-gray-600 leading-6 mt-5">
            If you are looking for someone with years of experience in
            bookkeeping as well as corporate taxes give us a call!
          </p>
        </div>
        <div className="lg:block hidden col-span-1">
          <img
            src={require("../../assets/corporate-1.jpg")}
            loading="lazy"
            className="w-full h-full object-cover"
            alt=""
          />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Corporate;
