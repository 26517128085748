import React from "react";
import Footer from "../components/Footer/Footer";
import Header2 from "../components/Header/Header2";
import { IoIosArrowDown } from "react-icons/io";
import FileTaxing from "../components/popup/FileTaxing";
import ScrollTop from "../components/scrollTop/ScrollTop";

function About() {
  return (
    <>
      <ScrollTop />
      <div className="absolute top-0 left-0 right-0 z-10">
        <Header2 />
      </div>
      {/* <div className="fixed bottom-14 animate-bounce duration-700 ease-linear left-5 z-10">
        <WhatsAppSvg />
      </div> */}
      <FileTaxing />
      <div className="bg-about bg-bottom">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 max-w-7xl mx-auto pt-56 pb-32">
          <div className="col-span-1 px-5 lg:px-0">
            <p className="text-4xl lg:-wider lg:leading-[55px] font-semibold text-white">
              The Name You Can Trust for World-Class Accounting Solutions
            </p>
            <p className="text-white mt-5 mb-16">
              Maurice Morneau Tax was founded by reputed accounting and taxation
              experts over three decades ago. Since the time of our advent, we
              have strived towards excellence in providing nothing but the best
              accounting services.
            </p>
            <p>
              <a
                href={"#about"}
                className="bg-gradient-to-l rounded from-[#2c4699] to-[#3c53be] uppercase text-white px-5 py-2.5 font-bold"
              >
                about us
              </a>
            </p>
          </div>
          <div className="col-span-1"></div>
        </div>
        <div className="flex items-center justify-center pb-3 animate-bounce duration-300">
          <IoIosArrowDown size={28} className="text-white" />
        </div>
      </div>
      <div className="max-w-7xl mx-auto py-16 px-5 lg:px-0" id="about">
        <p className="text-2xl font-normal max-w-lg leading-10 text-gray-700 mb-10">
          We always make sure to provide the best accounting and taxation
          services
        </p>
        <div className="space-y-5 font-normal text-gray-500">
          <p>
          Maurice Morneau Tax was founded by reputed accounting and taxation experts over
            three decades ago. Since the time of our advent, we have strived
            towards excellence in providing nothing but the best accounting
            services.
          </p>
          <p>
            With sheer determination, dedication and enthusiasm, we always make
            sure to provide the best accounting services to all of our clients.
            Understanding that the individual needs for every business are
            different, we always customize our services to cater to your
            specific needs.
          </p>
          <p>
            Apart from maintaining your corporate records, we also provide
            consulting services in order to help you develop a plan for your
            taxes now in order to save you money later. We are always ready, if
            not eager, to adapt to the changing world, politics, technology and
            technicality.
          </p>
          <p>
            Irrespective of the size and nature of your business, we have
            achieved years of experience and expertise in providing the best
            accounting services tailored to your requirements.
          </p>
          <p>
            So, if you are looking for help with any tax related problem our
            expert consultants will be glad to assist you! Why wait, please call
            us today.
          </p>
        </div>
      </div>
      {/* <img
        src={require("../assets/awards.png")}
        loading="lazy"
        className="mx-auto mb-5"
        alt=""
      /> */}
      <Footer />
    </>
  );
}

export default About;
